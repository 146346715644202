<template>
	 <v-app>
        <v-card width="300" outlined class="mx-auto my-auto" :loading="loading">
            <v-container class="my-auto">
                <v-row>
                    <v-card-title>
                        <v-icon large left>mdi-sigma</v-icon>
                        <span class="title font-weight-light">Reset Password</span>
                    </v-card-title>
                </v-row>

                <v-row>
                    <v-form ref="loginForm" autocomplete="chrome-off">
                        <v-col lg="12" md="12" xs="12" cols="12">
                            <v-text-field label="Password" solo flat prepend-inner-icon="mdi-email" type="password" v-model="password" />
                            <v-text-field label="Confirm Password" solo flat prepend-inner-icon="mdi-email" type="password" v-model="repassword" />
                        </v-col>
                    </v-form>
                </v-row>

                <v-row justify="center"></v-row>

                <v-row justify="center" v-if="resetStatus == 1">
                    <v-col lg="12" md="12" xs="12" cols="12">
                        <v-alert type="success" dense >
                            Password reset successfully
                        </v-alert>
                    </v-col>
                </v-row>

                <v-row justify="center">
                    <v-col lg="12" md="12" xs="12" cols="12" align="center">
                        <v-btn block color="info" :loading="btnLoading" @click="resetPassword">
                        	RESET PASSWORD
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </v-app>
</template>
<script>
import * as apiResetPassword from "@components/api/authpk/reset-password";
import { snackbar } from "@components/mixins/snackbar";

export default {
    props: {},
    mixins: [snackbar],
    data () {
        return {
            password: "",
            repassword: "",
            btnLoading: false,
            resetStatus: -1
        };
    },
    methods: {
        /**
		 * Forget password post method
		 * @return {[type]} [description]
		 */
        resetPassword () {
            const self = this;
            if (self.password == null || self.password == "" || self.password == undefined) {
                self.snackBarDanger("Please fill in password");
                return;
            }
            if (self.repassword == null || self.repassword == "" || self.repassword == undefined) {
                self.snackBarDanger("Please fill in retype password");
                return;
            }
            if (self.password != self.repassword) {
                self.snackBarDanger("Password and confirm password does not equal");
                return;
            }
            const params = { new_password: self.password };
            self.btnLoading = true;
            apiResetPassword.create(params).then(res => {
                setTimeout(() => {
                    self.btnLoading = false;
                    self.resetStatus = 1;
                    localStorage.removeItem("bst");
                    self.$router.push({ name: "Signin" });
                }, 2400);
            });
        }
    },
    mounted () {
        const token = this.$route.query.token;
        localStorage.setItem("bst", token);
    }
};
</script>
